<template>
  <ion-list-header color="light">
    <ion-row>
      <ion-text class="xLargeFont ion-align-self-center">
        Actividades
      </ion-text>
      <ion-fab-button
        class="ion-margin-left"
        size="small"
        v-if="isEditable"
        @click="modalCreateActividad = true"
      >
        <ion-icon color="light" :md="icons.add" :ios="icons.add"></ion-icon>
      </ion-fab-button>
    </ion-row>
  </ion-list-header>
  <ion-list lines="full" v-for="actividad in actividades" :key="actividad._id">
    <ion-item
      @click="goToEditActividad(actividad)"
      button="true"
      type="button"
      :disabled="!isEditable"
      class="itemBorder disabledOpacity"
    >
      <ion-row class="ion-align-items-start" style="margin-bottom: 4px">
        <ion-col class="margen8pxArriba smallFont" size="auto">
          <ion-text>
            {{ formatDate(actividad.fecha) }}
          </ion-text>
        </ion-col>
        <ion-col size="auto">
          <ion-thumbnail>
            <ion-img
              src="assets/icon/actividades/icoCosecha.png"
              v-if="actividad.tipo === 'Cosecha'"
            />
            <ion-img
              src="assets/icon/actividades/icoSiembra.png"
              v-if="actividad.tipo === 'Siembra'"
            />
            <ion-img
              src="assets/icon/actividades/icoFertiliza.png"
              v-if="actividad.tipo === 'Fertilización'"
            />
            <ion-img
              src="assets/icon/actividades/icoPulveriza.png"
              v-if="actividad.tipo === 'Pulverización'"
            />
            <ion-img
              src="assets/icon/actividades/icoRiego.png"
              v-if="actividad.tipo === 'Riego'"
            />
            <ion-img
              src="assets/icon/fichaLote/icoActividad.png"
              v-if="actividad.tipo === 'Otra'"
            />
          </ion-thumbnail>
        </ion-col>
        <ion-col size="auto" class="margen8pxArriba smallFont">
          <ion-text>
            {{
              actividad.tipo !== "Otra"
                ? actividad.tipo
                : (actividad.tipoOtraActividad !== "Otro" ? actividad.tipoOtraActividad : actividad.nombreOtraActividad)
            }}
          </ion-text>
        </ion-col>
        <ion-col
          class="smallerFont"
          style="float: right"
          :style="setMargin(actividad.tipo)"
        >
          <ion-row class="ion-justify-content-start">
            <ion-text style="white-space:normal">
              <b>
                {{ getDetailsTitle(actividad.tipo) }}
              </b>
            </ion-text>
          </ion-row>
          <ion-row class="ion-justify-content-start">
            <ion-text style="white-space:pre-line">
              {{ getDetailsData(actividad) }}
            </ion-text>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-item>
  </ion-list>

  <ion-modal
    id="modalCreateActividad"
    :is-open="modalCreateActividad"
    @didDismiss="modalCreateActividad = false"
    :backdropDismiss="false"
  >
    <ion-card>
      <ion-card-title class="ion-margin-top" style="margin-left:16px">
        Elija un Tipo de Actividad
      </ion-card-title>
      <ion-grid fixed>
        <ion-row>
          <ion-col style="width:25%">
            <ion-item
              lines="none"
              button="true"
              @click="
                modalCreateActividad = false;
                goToCreateActividad('Siembra');
              "
            >
              <ion-grid fixed>
                <ion-row class="ion-justify-content-center">
                  <ion-thumbnail>
                    <img src="assets/icon/actividades/icoSiembra.png" />
                  </ion-thumbnail>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text style="font-size:80%">Siembra</ion-text>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-col>
          <ion-col style="width:25%">
            <ion-item
              lines="none"
              button="true"
              @click="
                modalCreateActividad = false;
                goToCreateActividad('Fertilización');
              "
            >
              <ion-grid fixed>
                <ion-row class="ion-justify-content-center">
                  <ion-thumbnail>
                    <img src="assets/icon/actividades/icoFertiliza.png" />
                  </ion-thumbnail>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text style="font-size:80%">Fertilización</ion-text>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-col>
          <ion-col style="width:25%">
            <ion-item
              lines="none"
              button="true"
              @click="
                modalCreateActividad = false;
                goToCreateActividad('Riego');
              "
            >
              <ion-grid fixed>
                <ion-row class="ion-justify-content-center">
                  <ion-thumbnail>
                    <img src="assets/icon/actividades/icoRiego.png" />
                  </ion-thumbnail>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text style="font-size:80%">Riego</ion-text>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col style="width:25%">
            <ion-item
              lines="none"
              button="true"
              @click="
                modalCreateActividad = false;
                goToCreateActividad('Pulverización');
              "
            >
              <ion-grid fixed>
                <ion-row class="ion-justify-content-center">
                  <ion-thumbnail>
                    <img src="assets/icon/actividades/icoPulveriza.png" />
                  </ion-thumbnail>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text style="font-size:80%">Pulverización</ion-text>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-col>
          <ion-col style="width:25%">
            <ion-item
              lines="none"
              button="true"
              @click="
                modalCreateActividad = false;
                goToCreateActividad('Cosecha');
              "
            >
              <ion-grid fixed>
                <ion-row class="ion-justify-content-center">
                  <ion-thumbnail>
                    <img src="assets/icon/actividades/icoCosecha.png" />
                  </ion-thumbnail>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text style="font-size:80%">Cosecha</ion-text>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-col>
          <ion-col style="width:25%">
            <ion-item
              lines="none"
              button="true"
              @click="
                modalCreateActividad = false;
                goToCreateActividad('Otra');
              "
            >
              <ion-grid fixed>
                <ion-row class="ion-justify-content-center">
                  <ion-thumbnail>
                    <img src="assets/icon/fichaLote/icoActividad.png" />
                  </ion-thumbnail>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text style="font-size:80%">Otra</ion-text>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-col>
        </ion-row>
        <!-- <ion-row>
          <ion-col style="width:25%">
            <ion-item
              lines="none"
              button="true"
              @click="
                modalCreateActividad = false;
                goToCreateActividad('Riego');
              "
            >
              <ion-grid fixed>
                <ion-row class="ion-justify-content-center">
                  <ion-thumbnail>
                    <img src="assets/icon/actividades/icoRiego.png" />
                  </ion-thumbnail>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text style="font-size:80%">Riego</ion-text>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-col>
        </ion-row> -->
      </ion-grid>
      <ion-button
        @click="modalCreateActividad = false"
        class="ion-margin-top ion-float-right ion-margin-bottom"
        style="margin-right:16px"
      >
        Volver
      </ion-button>
    </ion-card>
  </ion-modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  IonButton,
  IonImg,
  IonModal,
  IonRow,
  IonGrid,
  IonCol,
  IonCard,
  IonCardTitle,
  IonList,
  IonListHeader,
  IonItem,
  IonIcon,
  IonFabButton,
  IonThumbnail,
  IonText,
  alertController
} from "@ionic/vue";
import { Actividades, Rinde } from "@/api";
import { addOutline } from "ionicons/icons";
import format from "@/utils/dateFormat";

export default {
  components: {
    IonButton,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonThumbnail,
    IonCard,
    IonCardTitle,
    IonFabButton,
    IonList,
    IonListHeader,
    IonIcon,
    IonModal,
    IonImg
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) }
  },

  data: () => ({
    modalCreateActividad: false,
    actividades: [],
    rindeMap: {},
    icons: {
      add: addOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    idProductor() {
      return this.$route.params.idProductor;
    },
    idLote() {
      return this.$route.params.idLote;
    },
    isEditable() {
      return this.campaniaCultivo &&
        (this.campaniaCultivo.abierto === true ||
          this.campaniaCultivo.abierto === false)
        ? this.campaniaCultivo.abierto
        : true;
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
  },

  watch: {
    async campaniaCultivo() {
      await this.dataSync();
    },
    async idLote() {
      await this.dataSync();
    },
    async idProductor() {
      await this.dataSync();
    }
  },

  methods: {
    ...mapActions(["getMe"]),

    formatDate(value) {
      if (!value) return "";
      return format(value, "dd/MM/yyyy");
    },

    async dataSync() {
      this.actividades = await this.getActividades();
      this.rindeMap = {};
      this.actividades.forEach(async a => {
        await this.getRindeActividad(a._id);
      });
    },

    goToCreateActividad(tipo) {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-createActividad",
          params: {
            idProductor: this.idProductor,
            idLote: this.campaniaCultivo.lote,
            tipoActividad: tipo,
            fecha: "sf"
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      } else {
        this.$router.push({
          name: "createActividad",
          params: {
            idLote: this.campaniaCultivo.lote,
            tipoActividad: tipo,
            fecha: "sf"
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      }
    },

    goToEditActividad(actividad) {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-editActividad",
          params: {
            idProductor: this.idProductor,
            idLote: this.campaniaCultivo.lote,
            idActividad: actividad._id
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      } else {
        this.$router.push({
          name: "editActividad",
          params: {
            idLote: this.campaniaCultivo.lote,
            idActividad: actividad._id
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      }
    },

    getDetailsTitle(tipoActividad) {
      switch (tipoActividad) {
        case "Siembra":
          return "Densidad";
        case "Fertilización":
          return "Productos";
        case "Pulverización":
          return "Plagas y Productos";
        case "Cosecha":
          return "Rendimiento";
        case "Riego":
          return "Mílimetros de Riego";
        default:
          return "";
      }
    },

    getDetailsData(actividad) {
      switch (actividad.tipo) {
        case "Siembra":
          return actividad.densidad ? actividad.densidad + " semillas/m2" : "-";
        case "Fertilización":
          return this.concatProductosFertilizantes(
            actividad.productosFertilizacion
          );
        case "Pulverización":
          return this.concatPlagasProductos(actividad.plagasPulverizacion);
        case "Cosecha":
          return this.rindeMap[actividad._id] &&
            this.rindeMap[actividad._id].rendimiento
            ? this.rindeMap[actividad._id].rendimiento + " kg/ha"
            : "-";
        case "Riego":
          return actividad.mmRiego ? actividad.mmRiego + " mm" : "-";
        default:
          return "";
      }
    },

    setMargin(tipoActividad) {
      switch (tipoActividad) {
        case "Siembra":
          return "margin-left:53px";
        case "Fertilización":
          return "margin-left:31px";
        case "Pulverización":
          return "margin-left:24px";
        case "Cosecha":
          return "margin-left:51px";
        case "Riego":
          return "margin-left:67px";
        default:
          return "margin-left:24px";
      }
    },

    concatProductosFertilizantes(productos) {
      if (!productos || !productos.length) return "-";
      return productos
        .map(p =>
          p.producto.nombre
            ? p.producto.nombre + this.dosisTxt(p.dosis, "kg/ha")
            : ""
        )
        .join(", ");
    },

    concatPlagasProductos(plagas) {
      if (!plagas || !plagas.length) return "-";
      let txt = "";
      plagas.forEach(plaga => {
        let flag = false;
        switch (plaga.tipoPlaga) {
          case "Maleza":
            txt += `*${plaga.maleza.nombre}: `;
            break;
          case "Enfermedad":
            txt += `*${plaga.enfermedad.nombre}: `;
            break;
          case "Insecto":
            txt += `*${plaga.insecto.nombre}: `;
            break;
          default:
            flag = true;
            break;
        }
        if (plaga.productosPlagas && plaga.productosPlagas.length && !flag) {
          txt += `${plaga.productosPlagas
            .map(p =>
              p.producto && p.producto.nombre
                ? p.producto.nombre + this.dosisTxt(p.dosis, p.unidad)
                : ""
            )
            .join(", ")}. \n`;
        } else {
          txt += "- \n";
        }
      });
      return txt;
    },

    async getRindeActividad(idActividad) {
      const { data, error } = await Rinde.get({
        query: {
          campaniaCultivo: this.campaniaCultivo._id,
          actividad: idActividad
        }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo datos de Rinde.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      let rinde = {};
      if (data.length) {
        rinde = data[0];
      } else {
        rinde = data;
      }
      this.rindeMap[idActividad] = rinde;
    },

    dosisTxt(dosis, unidad) {
      if (dosis && Number(dosis)) {
        if (unidad) {
          return " (" + dosis + " " + unidad + ")";
        }
        return " (" + dosis + " u.m.)";
      }
      return "";
    },

    async getActividades() {
      const { data, error } = await Actividades.get({
        query: { campaniaCultivo: this.campaniaCultivo._id },
        populate: JSON.stringify([
          { path: "productosFertilizacion" },
          { path: "productosFertilizacion", populate: "producto" },
          { path: "plagasPulverizacion" },
          {
            path: "plagasPulverizacion",
            populate: [
              "maleza",
              "insecto",
              "enfermedad",
              "productosPlagas",
              "productosPlagas.producto"
            ]
          }
        ]),
        sort: { fecha: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo actividades",
          translucent: true,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    }
  }
};
</script>

<style scoped>
ion-modal {
  --ion-background-color: #ffffff85;
}

.whiteFontColor {
  --ion-color-constrast: #ffffff;
}

.negrita {
  font-size: 175%;
  margin-left: 0px;
  font-weight: bold;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.smallFont {
  font-size: small;
}

.smallerFont {
  font-size: x-small;
}

.itemBorder {
  --border-color: var(--ion-color-secondary);
}

.itemColor {
  --background: rgba(var(--ion-color-primary-rgb), 0.6);
  --border-color: rgba(var(--ion-color-primary-rgb), 0.6);
}

.margen8pxArriba {
  margin-top: 8px;
}

.disabledOpacity {
  opacity: 1 !important;
}
</style>
